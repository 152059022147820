<template>
  <div style="width: 100%">
    <v-row>
      <v-col cols="12" xl="7" lg="7">
        <v-toolbar dense flat class="mt-2"> </v-toolbar>
      </v-col>
      <v-col cols="12" xl="5" lg="5">
        <v-toolbar dense flat>
          <v-text-field
            v-model="search"
            :append-outer-icon="search ? 'mdi-send' : ''"
            label="search"
            single-line
            hide-details
            clearable
            append-icon="mdi-magnify"
            @click:append-outer="updateSearch"
            @click:clear="clearSearch"
          ></v-text-field>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="tenants"
      :options.sync="options"
      :server-items-length="totalTenants"
      :loading="loading"
      :height="tableHeight"
      class="elevation-1"
      fixed-header
      dense
      must-sort
      v-resize="onResize"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20, 50],
      }"
    >
      <template v-slot:[`item.logExpireDate`]="{ item }">{{
        item.logExpireDate | date
      }}</template>
    </v-data-table>
  </div>
</template>

<script>
import tableHeightMixin from "@/mixins/tableHeightMixin";
import LoggerService from "@/services/WallSignLoggerService";

export default {
  name: "WallSignLogger",
  mixins: [tableHeightMixin],
  data: () => ({
    totalTenants: 0,
    tenants: [],
    loading: true,
    downloader: {},
    options: {
      itemsPerPage: 20,
      page: 1,
    },
    dialog: {
      //
    },
    search: null,
    filters: {
      //
    },
  }),
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    platformId: function () {
      this.getDataFromApi();
    },
  },
  computed: {
    headers: function () {
      return [
        {
          text: "Id",
          value: "id",
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Expire date",
          value: "logExpireDate",
        },
      ];
    },
  },
  methods: {
    updateSearch: function () {
      this.getDataFromApi();
    },
    clearSearch: function () {
      this.search = null;
      this.getDataFromApi();
    },
    getDataFromApi: function () {
      this.loading = true;
      LoggerService.getTenantsList({
        pageNumber: this.options.page,
        pageSize: this.options.itemsPerPage,
        sortBy: this.options.sortBy[0],
        sortDescending: this.options.sortDesc[0],
        search: this.search,
      })
        .then((result) => {
          this.tenants = result.data.data;
          this.totalTenants = result.data.totalRecords;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
